<template>
  <div class="page-box">
    <div class="shop-info">
      <div class="shop-logo">
        <img :src="shopDetail.logo" alt="">
      </div>
      <div class="info-content">
        <div class="shop-name">
          <span>{{shopDetail.name}}</span>
          <span class="auth-state" v-if="shopDetail.authData" :style="{color: shopDetail.authData.color, borderColor: shopDetail.authData.color}">{{shopDetail.authData.name}}</span>
        </div>
        <p class="desc">{{shopDetail.intro}}</p>
        <p class="info-total">商品件数：{{shopDetail.productNum}}件 ｜ 历史采集件数：{{shopDetail.historyCollectionProductNum}}件 <span v-if="shopDetail.serviceTel">客服电话：{{shopDetail.serviceTel}}</span><span v-if="shopDetail.afterServiceTel">售后电话：{{shopDetail.afterServiceTel}}</span></p>
      </div>
    </div>
    <base-screen>
      <a-form-model
        ref="formRef"
        layout="inline"
        :model="queryRef"
        @submit="handleSearch"
        @submit.native.prevent
      >
        <a-form-model-item label="商品名称" prop="name">
          <a-input v-model="queryRef.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="采集状态" prop="collectedState">
          <a-select v-model="queryRef.collectedState" placeholder="请选择" style='width:100px'>
            <a-select-option :value="1">
              未采集
            </a-select-option>
            <a-select-option :value="0">
              已采集
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="成本价" prop="lowTradePrice">
          <div class="custom-link-box">
            <a-input
              v-model.number="queryRef.lowTradePrice"
              placeholder="请输入"
              prefix="￥"
              @input="inputFormat('lowTradePrice', arguments[0])"
            />
            <span class="connector">-</span>
            <a-form-model-item prop="highTradePrice">
              <a-input
                v-model.number="queryRef.highTradePrice"
                placeholder="请输入"
                prefix="￥"
                @input="inputFormat('highTradePrice', arguments[0])"
              />
            </a-form-model-item>
          </div>
        </a-form-model-item>
        <a-form-model-item label="利润率" prop="lowProfitMargin">
          <div class="custom-link-box">
            <a-input v-model="queryRef.lowProfitMargin" placeholder="请输入" suffix="%" />
            <span class="connector">-</span>
            <a-form-model-item prop="highProfitMargin">
              <a-input v-model="queryRef.highProfitMargin" placeholder="请输入" suffix="%" />
            </a-form-model-item>
          </div>
        </a-form-model-item>
        <a-form-model-item label="商品类目" prop="productCategoryId">
          <div class="category-result">
            <p class="result-text" v-if="queryRef.productCategoryId&&categorySelected">{{categorySelected}}</p>
            <a-button :type="queryRef.productCategoryId ? 'link' : ''" @click="categorySettingShow = true">
              {{queryRef.productCategoryId ? '修改类目' : '选择商品类目'}}
            </a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div style='padding:16px 0;'>
      <a-checkbox :disabled='dataRef.filter(x=>x.collectedState).length===0' :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
        全选
      </a-checkbox>
      <a-button style='margin-left:32px;' type='primary' @click='openOnceCollect' :disabled='selectIds.length===0'>批量采集</a-button>
      <a-button type='link' v-if='selectProducts.length' @click='showSelectProductModal'>查看已选商品</a-button>
    </div>
    <base-tabs :tabs="stateList" :active="queryRef.sortBy" @change="handleStateChange" />
    <a-spin :spinning="loadingRef">
      <div class="custom-goods-list">
        <div v-if="dataRef.length" class="list-box fix">
          <div class="goods-item-box" v-for="item in dataRef" :key="item.productId">
            <div class="goods-item">
              <div class="item-info-box" >
                <div class="goods-img rect-100">
                  <base-img
                    class="full"
                    :src="item.picPath"
                    width="150"
                    height="150"
                  />
                </div>
                <p class="goods-name el2">{{item.name}}</p>
                <div class="goods-info">
                  <div>
                    <p>成本价：¥{{item.tradePrice}}</p>
                    <p>利润率：<span class="mark">{{item.profitMargin}}%</span></p>
                  </div>
                  <a href="javascript:;" @click="handleCollect(item)" :class="{disabled: !item.collectedState}" class="btn-collect">采集</a>
                </div>
                <p>零售价：¥{{item.minPrice}}<span v-if="item.minPrice!==item.maxPrice">~¥{{item.maxPrice}}</span></p>
                <div>
                  <a-checkbox @change="checkChange($event,item)" :checked="selectIds.indexOf(item.productId) !== -1" :disabled='!item.collectedState'></a-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="padding: 12px 0">
          <a-empty />
        </div>
      </div>
      <div class="custom-pagination">
        <a-pagination
          v-model="pageRtv.current"
          size="small"
          :page-size="pageRtv.size"
          :total="pageRtv.total"
          :show-total="total => `共 ${total} 条`"
        />
      </div>
    </a-spin>
        <a-modal :width='820' v-model="selectProductModalShow" :title="'已选'+modalSelectProducts.length+'件商品'" @ok="handleOk" @cancel='handleCancel'>
      <div class='modal-goods-list' style='width:760px'>
        <div class="modal-goods-list-box fix" v-if='modalSelectProducts.length'>
          <div class="modal-goods-item-box" v-for="(item,index) in modalSelectProducts" :key="item.productId">
            <div class="modal-goods-item">
              <base-img class="modal-goods-img" :src="item.picPath" />
              <div style='flex:1;flex-shrink:0;width:calc(100% - 92px)'>
                <p class="modal-goods-name el">{{item.name}}</p>
                <div class='modal-goods-shop-name el'>{{item.shopName}}</div>
                <div style='text-align:right;'>
                  <a-button type='link' icon='delete' class='delete-btn' @click='deleteChecked(index)'>移除</a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="padding: 12px 0">
          <a-empty description='请选择商品' />
        </div>
      </div>
    </a-modal>
    <a-modal v-model='showOnceCollectModal' class='once-modal' :width='640' :title='"批量采集(共"+modalSelectProducts.length+"件商品)"' @ok='onceCollect' :confirm-loading="onceCollectConfirmLoading">
        <a-form-model ref="paramRef" :model="form" :label-col="{span:6}"
        :wrapper-col="{span: 18}" :rules="rules">
        <a-form-model-item v-if="isPlatform" label="与上级一致" prop="sameForUpFlag">
          <a-switch checked-children="是" un-checked-children="否" v-model='form.sameForUpFlag' />
        </a-form-model-item>
        <a-form-model-item label="供货价加价率" prop="tradePriceMarkup" v-if="!form.sameForUpFlag">
          <a-input style='width:120px' v-model.trim="form.tradePriceMarkup" placeholder="请输入" addonAfter="%" />
        </a-form-model-item>
        <a-form-model-item label="零售价加价率" prop="priceMarkup" v-if="!form.sameForUpFlag">
          <a-input style='width:120px' v-model.trim="form.priceMarkup" placeholder="请输入" addonAfter="%" />
        </a-form-model-item>
        <a-form-model-item label="划线价加价率" prop="originalPriceMarkup" v-if="!form.sameForUpFlag">
          <a-input style='width:120px' v-model.trim="form.originalPriceMarkup" placeholder="请输入" addonAfter="%" />
        </a-form-model-item>
        <a-form-model-item label="精度值" prop="roundingType" v-if="!form.sameForUpFlag">
          <a-radio-group v-model="form.roundingType">
          <a-radio :value="0">
            保留整数
          </a-radio>
          <a-radio :value="1">
            保留1位小数
          </a-radio>
          <a-radio :value="2">
            保留2位小数
          </a-radio>
        </a-radio-group>
        <div class='tips'>精度值设置只作用于“零售价”，“划线价”，且精度取舍不影响加价率。</div>
        </a-form-model-item>
        <a-form-model-item label="同步信息" prop="syncFlag">
          <a-switch checked-children="开" un-checked-children="关" v-model='form.syncFlag' />
          <div class='tips'>
            <a-icon type="warning" :style="{color: '#f56c6c'}" /> 关闭该功能会导致商品信息与上级商品信息不同步
          </div>
        </a-form-model-item>

        <a-form-model-item label="商品分组" prop="productGroupId">
          <a-spin class="custom-spin" :spinning="getGroupListLoading" size="small">
            <a-select showSearch v-model="form.productGroupId" placeholder="请选择" allowClear>
              <a-select-option v-for="d in productGroupList" :key="d.productGroupId">
                <span class="custom-select-option">{{d.groupName}}</span>
              </a-select-option>
            </a-select>
          </a-spin>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <modal-category-select-any-level :show.sync="categorySettingShow" :ids-map-str.sync="categoryInitStr" @confirm="handleCategoryConfirm" />
  </div>
</template>

<script>
import { ref, reactive, toRefs, watch, onUnmounted, onMounted } from '@vue/composition-api'
import ModalCategorySelectAnyLevel from './ModalCategorySelectAnyLevel'
import { common, goods } from '@/api'
import NP from 'number-precision'
import { useTableList } from '@/hooks'
import { shopAuthStates } from '@/utils/constants'
import { SHOPID } from '../../constants'
import { getSession } from '@/utils/session'
import { isPlatform } from '../../utils/tools'
const deepClone = (obj) => {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // 判断obj子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          // 如果不是，简单复制
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
const checkRatio = (rule, value, callback) => {
  if (value) {
    const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
    if (!reg.test(value)) {
      return callback(new Error('整数或最多两位小数'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}
const stateList = [
  { title: '综合', value: 0 },
  { title: '利润', value: 1 },
  { title: '销量', value: 2 },
  { title: '成本价', value: 3 },
  { title: '上架时间', value: 4 }
]
export default {
  name: 'PageShopGoods',

  props: {
    shopId: String
  },
  components: {
    ModalCategorySelectAnyLevel,
  },
  setup (props, { root }) {
    // 获取店铺信息
    const shopDetail = ref({})
    async function getShopDetail () {
      const { data } = await goods.getShopDetail(props.shopId)
      shopDetail.value = {
        ...data,
        logo: data.logo ? `${process.env.VUE_APP_IMG}${data.logo}` : require('../../assets/default-head.svg'),
        authData: shopAuthStates.find(x => x.value === data.authState)
      }
    }

    const state = reactive({
      categorySettingShow: false,
      categoryInitStr: '',
      categorySelected: '',
      currentShopId: getSession(SHOPID),
      indeterminate: false,
      checkAll: false,
      selectIds: [],
      onceCollectConfirmLoading: false,
      selectProducts: [],
      selectProductModalShow: false,
      modalSelectProducts: [],
      showOnceCollectModal: false,
      form: {
        priceMarkup: 10,
        originalPriceMarkup: 10,
        tradePriceMarkup: 10,
        roundingType: 2,
        sameForUpFlag: false,
        syncFlag: true
      },
      paramRef: null,
      getGroupListLoading: false,
      productGroupList: [],
      rules: {
        priceMarkup: [
          { required: true, message: '请输入', trigger: 'change' },
          { validator: checkRatio, trigger: 'change' }
        ],
        originalPriceMarkup: [
          { required: true, message: '请输入', trigger: 'change' },
          { validator: checkRatio, trigger: 'change' }
        ],
        tradePriceMarkup: [
          { required: true, message: '请输入', trigger: 'change' },
          { validator: checkRatio, trigger: 'change' }
        ],
        roundingType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        sameForUpFlag: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        syncFlag: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        productGroupId: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      }
    })
    const formRef = ref(null)
    const queryRef = ref({
      name: '',
      sortBy: 0,
      shopId: props.shopId,
      lowTradePrice: '',
      highTradePrice: '',
      lowProfitMargin: '',
      highProfitMargin: '',
      collectedState: undefined,
      currShopId: getSession(SHOPID)
    })

    const { dataRef, pageRtv, loadingRef, resetPage, setPage, refresh } = useTableList(goods.getShopGoodsList, queryRef, true, res => res || [])
    watch(
      () => state.selectIds,
      (v) => {
        const currentPageProductIds = dataRef.value
          .filter((x) => x.collectedState)
          .map((x) => x.productId)
        const num = v.filter((x) => currentPageProductIds.indexOf(x) !== -1).length
        if (num === 0) {
          state.indeterminate = false
          state.checkAll = false
        } else if (num === currentPageProductIds.length) {
          state.indeterminate = false
          state.checkAll = true
        } else {
          state.indeterminate = true
          state.checkAll = false
        }
      }
    )
    watch(
      () => dataRef.value,
      (v) => {
        const currentPageProductIds = v.filter((x) => x.collectedState).map((x) => x.productId)
        const num = state.selectIds.filter((x) => currentPageProductIds.indexOf(x) !== -1).length
        if (num === 0) {
          state.indeterminate = false
          state.checkAll = false
        } else if (num === currentPageProductIds.length) {
          state.indeterminate = false
          state.checkAll = true
        } else {
          state.indeterminate = true
          state.checkAll = false
        }
      }
    )
    function handleSearch () {
      resetPage()
      refresh()
    }
    onMounted(() => {
      getShopDetail()
      root.$bus.$on('CollectDetail:refresh', callback)
    })
    onUnmounted(() => {
      root.$bus.$off('CollectDetail:refresh', callback)
    })
    const callback = () => {
      refresh()
    }
    function handleReset () {
      formRef.value.resetFields()
      handleSearch()
    }

    function handleTableChange ({ current }) {
      setPage(current)
    }

    function handleStateChange (state) {
      queryRef.value.sortBy = state
      handleSearch()
    }

    function inputFormat (variable, e) {
      queryRef.value[variable] = e.target.value.replace(/[^0-9]/ig, '')
    }

    function handleCollect ({ productId, shopId, collectedState }) {
      if (!collectedState) return root.$message.warning('已采集过该商品')
      root.$router.push(`/goods/goods_collect_detail/${window.btoa(JSON.stringify({ upShopId: shopId, productId }))}`)
    }
    function checkChange (e, item) {
      if (e.target.checked) {
        if (state.selectIds.indexOf(item.productId) === -1) {
          state.selectIds.push(item.productId)
          state.selectProducts.push(item)
        }
      } else {
        if (state.selectIds.indexOf(item.productId) !== -1) {
          state.selectIds.splice(state.selectIds.indexOf(item.productId), 1)
          state.selectProducts.splice(
            state.selectProducts.findIndex((x) => x.productId === item.productId),
            1
          )
        }
      }
    }
    function deleteChecked (index) {
      state.modalSelectProducts.splice(index, 1)
    }
    function onCheckAllChange (e) {
      state.checkAll = e.target.checked
      const currentPageProductIds = dataRef.value
        .filter((x) => x.collectedState)
        .map((x) => x.productId)
      if (state.checkAll) {
        // 去重 合并
        state.selectIds = Array.from(new Set(currentPageProductIds.concat(state.selectIds)))
        let hash = {}
        state.selectProducts = state.selectProducts.concat(
          dataRef.value.filter((x) => x.collectedState)
        )
        state.selectProducts = state.selectProducts.reduce((item, next) => {
          if (!hash[next.productId]) {
            hash[next.productId] = true
            item.push(next)
          }
          return item
        }, [])
      } else {
        state.selectIds = state.selectIds.filter((x) => currentPageProductIds.indexOf(x) === -1)
        state.selectProducts = state.selectProducts.filter((x) => state.selectIds.indexOf(x) !== -1)
      }
    }
    function handleCancel () {
      state.selectProductModalShow = false
    }
    function handleOk () {
      state.selectProducts = deepClone(state.modalSelectProducts)
      state.selectIds = state.selectProducts.map((x) => x.productId)
      state.selectProductModalShow = false
    }
    function showSelectProductModal () {
      state.selectProductModalShow = true
      state.modalSelectProducts = deepClone(state.selectProducts)
    }
    function onceCollect () {
      state.paramRef.validate(async valid => {
        if (valid) {
          state.onceCollectConfirmLoading = true
          let param = Object.assign({}, {
            ...state.form,
            shopId: state.currentShopId,
            onceCollectProductItemList: state.selectIds.map(x => {
              return {
                productId: x
              }
            })
          })
          const arr = ['priceMarkup', 'originalPriceMarkup', 'tradePriceMarkup']
          for (let key in param) {
            if (arr.indexOf(key) !== -1 && param[key] !== 0) {
              param[key] = NP.divide(param[key], 100)
            }
          }
          const { code, msg } = await goods.onceCollect(param)
          state.onceCollectConfirmLoading = false
          if (code === '00000') {
            root.$success({
              title: '操作成功，采集结果以消息推送为准。',
              content: '建议您在接收到采集结果消息推送后手动刷新页面再进行下次采集。',
            })
            state.showOnceCollectModal = false
            state.selectIds = []
            state.selectProducts = []
            state.indeterminate = false
            state.checkAll = false
            state.paramRef.resetFields()
          } else {
            root.$message.error(msg || '批量采集失败')
          }
        }
      })
    }
    function openOnceCollect () {
      state.showOnceCollectModal = true
      getProductGroupList()
    }
    async function getProductGroupList () {
      state.getGroupListLoading = true
      const { data } = await common.getProductGroupByShopId()
      state.getGroupListLoading = false
      state.productGroupList = data || []
    }
    function handleCategoryConfirm (data) {
      queryRef.value.productCategoryId = data.code
      state.categorySelected = data.label
      checkNeedVoucher()
    }
    async function checkNeedVoucher () {
      const levels = []
      const level = queryRef.value.productCategoryId.length / 4 - 1
      for (let i = 0; i < level; i++) {
        if (i === 0) {
          levels.push(queryRef.value.productCategoryId.substr(0, 8))
        } else {
          levels.push(queryRef.value.productCategoryId.substr(0, 8 + i * 4))
        }
      }
    }
    return {
      isPlatform,
      ...toRefs(state),
      stateList,
      shopDetail,
      formRef,
      queryRef,
      dataRef,
      pageRtv,
      loadingRef,
      handleSearch,
      handleReset,
      handleTableChange,
      handleStateChange,
      inputFormat,
      handleCollect,
      checkChange,
      onCheckAllChange,
      showSelectProductModal,
      deleteChecked,
      onceCollect,
      openOnceCollect,
      getProductGroupList,
      handleOk,
      handleCancel,
      handleCategoryConfirm
    }
  }
}
</script>

<style lang="less" scoped>
.custom-spin {
  width: 170px;
  ::v-deep > div > .ant-spin .ant-spin-dot {
    left: initial;
    right: 18px;
  }
}
.custom-link-box {
  display: flex;
  align-items: center;
  .connector {
    margin: 0 3px;
  }
  ::v-deep .ant-input-affix-wrapper {
    width: 96px;
  }
}
.custom-pagination {
  text-align: right;
}
.shop-info {
  display: flex;
  padding-bottom: 24px;
  .shop-logo {
    flex: none;
    width: 48px;
    height: 48px;
    padding: 2px;
    margin-right: 12px;
    border-radius: 50%;
    border: 1px solid #DCDFE6;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .info-content {
    flex: 1;
  }
  .shop-name {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    line-height: 24px;
    .auth-state {
      display: flex;
      align-items: center;
      height: 18px;
      padding: 0 5px;
      margin-left: 16px;
      border: 1px solid #ddd;
      border-radius: 2px;
      font-size: 12px;
    }
  }
  .desc {
    font-size: 12px;
    color: #999;
    line-height: 24px;
  }
  .info-total {
    margin-top: 6px;
    border-top: 1px solid #DCDFE6;
    font-size: 14px;
    color: #666;
    line-height: 32px;
    span {
      margin-left: 20px;
      font-size: 12px;
      &:first-child {
        margin-left: 50px;
      }
    }
  }
}
.tips {
  font-size: 14px;
  color: #666;
  line-height: 20px
}
.once-modal {
  ::v-deep .ant-form-item{
    margin-bottom: 12px;
  }
}
.category-result {
  display: flex;
  align-items: center;
  margin-top: 4px;
  .result-text {
    height: 32px;
    padding: 0 8px;
    line-height: 32px;
    border-radius: 4px;
    border: 1px solid #c8c9cc;
    background: #fff;
  }
}
</style>
